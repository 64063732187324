import React from "react";

const Principles = () => {
  return (
    <div>
      <div>
        <h2>Rules for Life (But there are no rules!)</h2>
        <p>
          There are an infinite number of variables in life. Life is an
          n-dimensional equation to be solved. Below are some principles by
          which I default to make sense of the chaos. This is a living breathing
          document that is subject to change as I walk through the journey that
          is life.{" "}
        </p>
        <ol>
          <li>High standards are habitual.</li>
          <ul>
            <li>
              High standards become second nature when consistently upheld. As a
              founder, I've started to realized I need to become accustomed to
              the public eye. Considering I work 7 days a week, most of my life
              is work and not much of my life is private. Everyone I meet is a
              potential client, employee, or investor. It feels as though I am
              always performing, always pitching myself and that is because I
              am.
            </li>
            <li>Habitually help others and ask for help.</li>
          </ul>
          <li>Anything is possible. Truly.</li>
          <ul>
            <li>
              Your beliefs become your reality. As a founder, I'm consistently
              willing thoughts in my head into existence (like my company).
            </li>
            <li>
              My key observation from attending Harvard is that Harvard
              students (also applies to MIT/Stanford students) are no smarter
              than the average student. They just believe anything is possible.
              For example, when I interviewed an MIT student, I asked him if he
              know MUI/Next.js. He said no but he was sure he could learn it
              quickly. When I worked with a Vanderbilt grad, she would
              constantly tell me why something not possible instead of how it
              could be possible.
            </li>
            <li>
              I hate hate hate objections. (Side note: I'm really good at
              rolling objections and can basically roll any objection.) I hate
              people who talk about why they can't get something done.
            </li>
          </ul>

          <li>Work hard.</li>
          <ul>
            <li>
              For most of my life, I've been a grinder. And I love grinders. I
              dislike complacent people and can't spend too much time around
              them but that's probably only because I'm envious of them.
            </li>
            <li>
              Everytime I've worked hard, I've been rewarded with opportunity. I
              never thought at 24 I would be invited by the Chancellor of
              Vanderbilt to speak at my alma mater. I can't wait to see what
              opportunities the future holds.
            </li>
            <li>
              Preferably you work hard on something you are passionate about.
              It makes it working a lot easier and you will feel energized as
              you work more.
            </li>
          </ul>

          <li>
            Rule of Three
            <ul>
              <li>
                3 is my favorite number. At Harvard Law I was in Section 3.{" "}
              </li>
              <li>
                Only allow yourself to pick from 3 options to avoid option
                paralysis. When picking which law firm to use, only research 3
                to not waste your time and pick the best one.
              </li>
              <li>
                When structuring a large body of data (e.g. writing an essay),
                break it down into 3 subsections of logically related
                information. Break each section into 3 further subsections and
                repeat recursively as needed.
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div>
        <h2>Software Engineering Principles</h2>
        <p>
          In the realm of software engineering, certain principles guide the
          creation of robust, maintainable, and efficient code. These principles
          serve as a foundation for good software design and development
          practices. Here are a few key principles I adhere to:
        </p>
        <ol>
          <li>
            Keep it simple. Avoid unnecessary complexity and over-engineering.
            Simple code is easier to understand, maintain, and debug.
          </li>
          <li>
            DRY - Don't repeat yourself. Make reusable components. Every piece
            of logic should have a single representation in the system.
          </li>

          <li>
            You aren't going to need it. - Don't add additional functionality
            until it is absolutely necessary.
          </li>
          <li>
            Readability - Write code that is easy to read and understand rather
            than trying to be clever and solve a problem in as few lines as
            possible. Another human will inevitably have to read your code,
            debug, and maintain your code so you are just causing more headaches
            for someone else down the line.
          </li>
          <li>
            Keep up - Keep up with the newest frameworks being developed and
            introduced. I use X to stay up to date as there is a robust
            community of developers there. Migrate to new technologies where
            applicable as soon as possible to avoid accruing more tech debt.
          </li>
        </ol>
      </div>
    </div>
  );
};
export default Principles;

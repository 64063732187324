import React from "react";

const FiringCofounders = () => {
  return (
    <div>
      <p>December 12, 2024</p>
      <h2>I hope you never have to fire someone</h2>

      <p>
        There are many parallels between firing someone and breakups. Some
        adages hold true. There's no good way to do it. There's no good time to
        do it. There's no good way to do it. But as soon as you know, you just
        have to do it. I used to think that founders who fired their co-founders
        were stupid people and they must know nothing about building
        relationships and getting along with people. How naive I was. I met a
        guy in Prod (Harvard/Stanford/MIT startup accelerator) who went through
        8 co-founders before finding the right one.
      </p>

      <p>
        So before I have to sign a mutual NDA and delete this essay, here's the
        story of how I fired my co-founders.
      </p>

      <h2>I already subconsciously knew</h2>
      <p>
        It all started on the Prod trip where I was flown out for 3 days to San
        Francisco with 15 other founders. These were 15 of the most brilliant
        and committed startup founders. For example, one Harvard senior was
        taking a leave of absence to work on her startup despite not even having
        an idea. One guy gave up a 500k salary at a quantitative trading firm to
        self fund his startup. We spent Saturday afternoon hearing from and
        speaking to Sequoia founders in small fireside chats. During one of the
        panels, I found myself raising my hand to ask "How do you know when to
        fire someone?". The Sequoia partner said do it quickly, and when you do
        it, do it with grace. Perhaps this was the first manifestation that
        subconsciously, I already knew I had to fire my co-founders.
      </p>

      <p>
        I pitched Enttor to a Sequoia partner myself, and gave him my business
        card complete with an AI headshot. He was really interested and I was
        over the moon. Some founders work years before speaking to a Tier 1 fund
        or just never get the chance because they don't have the right
        connections. After all the events of the weekend, I slowly started
        realizing my co-founders were not of the same caliber as me. I couldn't
        speak to their accomplishments when asked. Not that they weren't smart
        people or good people. They just didn't know how to code and weren't as
        committed as me. I was doing most of the work for the company.
      </p>

      <p>
        The day I got back from the trip, I knew I had to fire them. We met
        online and I said that the co-founder relationship wasn't working and
        broke down crying. (So much like a relationship breakup right?) I was so
        emotional I also fired my software engineer (big mistake), who I then
        rehired. I so wanted to shut down the company since after firing 3
        people, Enttor was back to a team of 1. It was the perfect time to just
        give up and be a law student.
      </p>

      <h2>The legal nightmare</h2>
      <p>
        Silly me thought there would be no repercussions. The former CTO and CPO
        were friends. So they teamed up to compare notes and sent a formal
        notice asking for 24k and 18k respectively for 3 months of part-time
        work to be paid within 1 month. I agreed it was more than fair but the
        company had 0 dollars in the bank so this was an impossible ask. I also
        panicked and hired a Big Law firm. Turns out lawyers are $1000/hour.
        (Side note: what a lucrative career I'm forgoing) I was in a state of
        stress for 4 weeks. When lawyers get involved, you know it's serious.
      </p>

      <p>
        I met with them a second time to negotiate the terms of the severance,
        this time hoping I could keep it together. I couldn't stop myself from
        crying again. The former CTO hated my guts. Turns out I fired him the
        day he came back from a mental health leave. Although I was never
        explicitly told he was on a mental health break there were signs. I
        cared too much about the company and was too oblivious to see the
        symptoms. The former CPO seemed more friendly and wanted to stay in
        touch for future job opportunities. The whole ordeal could be summed up
        to a good cop/bad cop negotiation.
      </p>

      <h2>How to find good co-founders</h2>
      <p>
        As someone who hasn't found a co-founder, I can only theorize based on
        my mistakes. I think it's like dating. When you know you know. It should
        be glaringly obvious when you've found the right people to build a
        company with. There are a few criteria to guide your search.
      </p>

      <ul>
        <li>
          <b>Mission:</b> You should want the same things. If one person wants
          to build a unicorn software company and the other wants to open a
          coffee shop then obviously it's not a match. You don't want the same
          things out of the company. You don't want the same things out of life.
        </li>
        <li>
          <b>Effort:</b> All parties have to be equally committed and put the
          same effort or resentment will build. Which was slightly the case for
          me. Once you feel resentment building, address it right away or it
          will only blow up bigger later.
        </li>
        <li>
          <b>Skills:</b> Co-founders should have complementary skills. In my
          case, I was doing the coding, sales, and fundraising so no one was
          really complimenting my skills. I should have found someone who was
          twice as good of a software engineer than I am.
        </li>
        <li>
          <b>Caliber:</b> Co-founders should be at least as good as you if not
          better. This person should make you go "Wow". Co-founders should be
          constantly inspiring you to raise your standards.
        </li>
      </ul>

      <p>
        And like dating, you probably have to go on a lot of dates in a lot of
        different places before you find Prince Charming. I don't know honestly.
        I'm a solo founder now so you should probably take advice from someone
        who has a good co-founding team. I'm just writing this in hopes you can
        learn from my mistakes and avoid the emotional turmoil plus legal
        headache. Good luck. I hope you find your Prince Charming.
      </p>
    </div>
  );
};

export default FiringCofounders;

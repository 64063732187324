const Home = () => (
  <div>
    <h2>Introduction</h2>
    <p>
      Welcome! My name is Zinnie Zhang and I invite you to explore my website. I
      am currently based in Boston, drawing daily inspiration from its
      intellectual atmosphere as a 1L at Harvard Law School. I am originally
      from Fuzhou, China and grew up in the harsh winters of Toronto, Canada.
      Places that also have my heart include San Francisco (for it's tech
      scene), New York City (fun times in my early 20's and planning to move
      back soon), and Nashville (where I did my undergrad).
    </p>
    <p>
      At heart, I am a software engineer who loves to build things. During my
      career at Yext (enterprise marketing company), I observed that enterprises
      were paying millions of dollars annually for bad software, so I quit to
      code better marketing software myself. I'm the Founder of an marketing
      software startup called <a href="http://www.enttor.ai">Enttor</a>. If you
      are passionate about software + marketing and have experience with
      MongoDB/React/Next.js, please email your personal website and resume to my
      Enttor email. I'm actively looking for cracked software engineers.
    </p>

    <p>
      In my free time, I enjoy reading biographies, listening to podcasts, going
      to coffee shops, and Soulcycle.
    </p>

    <div>
      <h2>Connect with me:</h2>
      <ul>
        <li>
          <a href="https://x.com/zinniezhang">X</a>
          <p style={{ display: "inline" }}>
            {" "}
            - software engineering and startup stuff. Most responsive on X or
            email.{" "}
          </p>
          <li>
            <a href="https://www.linkedin.com/in/zinniezhang">LinkedIn</a>
            <p style={{ display: "inline" }}>
              {" "}
              - connect with me professionally.{" "}
            </p>
          </li>

          <li>
            <a href="https://www.threads.net/@zinniezhang">Threads</a>
            <p style={{ display: "inline" }}>
              {" "}
              - a casual place where I jot down my random thoughts. If you are
              curious how my brain works, this is the place to be.{" "}
            </p>
          </li>
          <li>
            <a href="https://www.instagram.com/zinniezhang">Instagram</a>
            <p style={{ display: "inline" }}>
              {" "}
              - a visual diary of my happy moments.{" "}
            </p>
          </li>
        </li>
      </ul>
      <h2>Contact Me:</h2>
      <li>Personal: xzhang@jd27.law.harvard.edu</li>
      <li>Business: zzhang@enttor.ai</li>
    </div>
  </div>
);

export default Home;

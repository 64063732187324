import React from "react";
import { Link } from "react-router-dom";

const Writing = () => {
  return (
    <div>
      <h2>Writing</h2>
      <p>
        Why write? Writing helps me gain clarity. Thoughts are just thoughts
        swirling around in the brain if not written down. Writing is also
        reflection. It helps me make sense of all my life experiences. Moreover,
        a great writer will actually discover new ideas in the process of
        writing. When I write my autobiography, my future self will thank me for
        all these personal essays I've journalled to document my life.{" "}
      </p>
      {/* Add new articles here and in App.js*/}

      <ul>
        {/*
        <li>
          <Link to="/writing/my-mother">My Mother</Link>
        </li>
        */}
        <li>
          <Link to="/writing/startup">How to Start a Startup</Link>
        </li>
        <li>
          <Link to="/writing/second-impressions">
            Second Impressions of Harvard Law School
          </Link>
        </li>
        <li>
          <Link to="/writing/overwhelmed">
            First Week of Harvard Law School
          </Link>
        </li>

        <li>
          <Link to="/writing/eldest-daughter">
            Unlearning How to Be an Eldest Daughter
          </Link>
        </li>
        <li>
          <Link to="/writing/principles">
            Rules for Life (But there are no rules!)
          </Link>
        </li>
        <li>
          <Link to="/writing/personal-essay">
            Personal Essay to Harvard Law School
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Writing;
